import React from "react";
import { useLocation } from "react-router-dom";

const SattaInfo = () => {
  const location = useLocation();
  const isPrivacy = location.pathname.includes("/privacy-policy");
  const isDisclaimer = location.pathname.includes("/disclaimer");
  const isAbout = location.pathname.includes("/about-us");
  return (
    <>
      {!(isPrivacy || isDisclaimer || isAbout) && (
        <div className="gr-twenty text-center mt-5">
          <div className="sattaking-info">
            <h2>About Satta</h2>
            <p>
              <a href="/">Satta King</a> or Satta Matka is currently the most
              growing number-based lottery game in India, Satta King roots back
              to colonial India. Even Satta King ancient history reckons that
              gambling was in our blood. Owing to that, it has grown bigger even
              after the independence. As of now, the estimated daily figure of
              Satta King is more than 500cr. Not to mention, it is just
              speculation.
            </p>
            <p>
              Aforesaid, Satta King is a number-based game. Nonetheless, the
              name of the game is not Satta King. Satta King used to be the
              title for the winner, who wins the game. Moreover, Satta Matka was
              the game. Earlier, when Satta Matka started, along with the
              winning amount, people used to get praise and respect from other
              bettors. Eventually, that mutual respect and friendly foresaying
              turned into the title. Currently, this title has earned so much
              popularity among the bettors that they've forgotten the name of
              the actual game. The name satta matka has been shadowed by the
              glory of the title, Satta King.
            </p>
            <p>Without further ado, let's move on to our topic.</p>
            <h3>What is Satta King?</h3>
            <p>
              As easy it may seem to speak, as complicated it is. There is a
              misconception among the people that Satta king is a game. However,
              it's not. Earlier, Satta King was the title given to the winner of
              satta. This title was not something that the lottery company came
              up with, and the title was given out of mutual respect, honor, and
              fun. Tho, when the game became huge people started using it even
              in general terms. Everyone related to satta was called Satta King,
              i.e., Khaiwal, Owner of the lottery company, etc. Additionally,
              every satta game was famous by the same title. Gali, Disawar,
              Ghaziabad, Faridabad, etc.
            </p>
            <h3>What is Satta Matka?</h3>
            <p>
              Satta Matka is a number-based lottery game in which your luck and
              precision help you to win the fortune. Before you guys wonder why
              it's called Satta Matka let me help unveil the dragon. Earlier,
              results for the game used to come out of the earthen pot, or
              "Matka" (another word for earthen pot). As a result of this, the
              term Matka was added to it.
            </p>
            <p>
              As prior mentioned, Satta Matka is a number-based game. It is
              played between 00 and 99. Bettors have to choose numbers between
              them and place bets on their desired number. Additionally, to make
              the game more interesting, lottery companies added Jodi and Dhara
              to the game. People can either predict the combination of numbers
              or a single number. Meanwhile, if someone did a precise
              prediction, they win. Furthermore, they receive the winning
              amount, either 9x or 90x of the waged money, complying with the
              satta they waged on ( If someone has waged ₹10, then 9x will be
              90, and 90x will be 900). Success in Satta Matka is totally
              luck-based if your fortune is favoring you. You can win loads of
              money overnight. If you don't, you can lose a big time.
            </p>
            <p>
              Though everyone plays the game <a href="/">Satta Matka</a>, they
              have very little idea about the name. Satta King's title became so
              popular and made its way into the colloquial language. Moreover,
              people know the game the same. Currently, Satta King is one of the
              most played lottery games, along with Gali, Disawar, and a few
              more. Greed and lust for easy money among people made it immense.
            </p>
          </div>
          <div className="sattaking-info">
            <h2>History of Satta King?</h2>
            <p>
              The story of the satta king begins prior to independent India. Its
              history dates back to the 1940s in colonial India. At that point,
              the Britishers used to import cotton from India via Bombay ports
              and New York Cotton Exchange used to fix the rate. Daily wage
              workers from there used to predict the pricing of the cotton
              trade. As it was their way to kill time, what else did they have
              back then? Sooner, this prediction game turned into a full-fledged
              satta.
            </p>
            <p>
              People started betting money on the cotton price. As they find it
              quite fascinating, how easy can money be made? The game, which
              started as time passed, it soon became a business. Few folks who
              saw the opportunity came up with the idea of this business. They
              started operating the satta. In a short time, stalls were opened
              outside of the port to play Satta. Satta was blooming at that very
              moment. The greed, the need, or the addiction everything helped
              Satta to boom.
            </p>
            <p>
              However, with the bliss of easy money, there came the boon.
              Workers started losing their daily wages over betting. They
              started getting addicted to the lust for easy money. After losing,
              they used to play more in chase of loosening money. When New York
              Cotton Exchange came to know about it, they banned all kinds of
              betting on cotton trades. Police started raiding the stalls.
              Operators faced a huge loss due to that. And an era of betting
              over cotton trades came to an end.
            </p>
            <p>
              Although, they were unable to ban the betting. Due to lucrative
              addiction to the game people came out with new ways to gamble.
              Satta Matka, was one such.
            </p>
            <p>
              As said above, satta matka is comprised of two words. "Satta"
              means betting, and "Matka" means earthen pot. All the given
              numbers are used to put under the earthen pot inside chits. Folks
              used to predict numbers and bet on the number that will come out
              of the pot (winning number). This game got so much popularity that
              it's still alive, till the time we're writing this and you're
              reading this.
            </p>
            <p>
              Afterward, in the 1960s, <a href="/">Ankara Jugad </a> (earlier
              name of satta king) see a huge rise. In 1962, Kalyanji Bhagat, a
              operator of Satta Matka started it on large scale, and named his
              lottery Kalyan Matka. It was a huge success. Kalyan Matka became a
              word-to-mouth name. Kalyanji Bhagat also gained huge popularity
              and people started calling him The Satta King. It is believed that
              Kalayanji Bhagat was the creator of Satta Matka. He started the
              idea of taking chit out of the earthen pot. Later, after seeing
              the popularity that Kalyan Matka, another bookie Ratan Khatri also
              started his own game in 1964, New Worli Matka. Tho the idea was
              same as Kalyan's but he introduced some rules to the game.
            </p>
            <p>
              Kalyan Matka used to run all day of the week. At the same time,
              the new worli matka used to run only five days a week. During the
              1990s, Satta gained all the name and fame. Daily betting volume
              was grown up to 500 crores. Gradually, other parts of India also
              tasted it. It became famous in other parts of India as well. The
              daily volume of satta was rising every day. In 2000s, satta matka
              faces the setback. Repetitive police raids damaged the bases of
              Satta Matka. Continuous interference from the police became a huge
              issue. Khaiwal started to hide.
            </p>
            <p>
              Stalls where people used to place bets closed down. Owing to that,
              the business faced a severe loss. Somehow, they still survived
              that. Everything went underground. Khaiwals, Bettors, Operators,
              everyone. No one had any idea where and how they operated. This
              kept them off the hook for a long time. In 2008, Suresh Bhagat,
              son of Kalyan Bhagat, was shot dead. After the demise of Suresh
              Bhagat, it was believed that satta would be shut forever.
              Irrespective of the hurdles satta has faced it still came back,
              better than before.
            </p>
            <p>As said in old texts, gambling is in our blood.</p>

            <h2>How to play Satta King?</h2>
            <p>
              Earlier, when it started, there was only one way to play it.
              People have to visit their local khaiwal or the khaiwal they
              trust. Khaiwal was the mediator between the bettors and the
              lottery company. They used to take money from the bettors and
              deliver it to the lottery company. It was their responsibility to
              deliver the result and win money for the bettors. However, after
              the police started intervening, placing bet became tough. Khaiwals
              went underground. Yet, people found ways to bet.
            </p>
            <p>
              Furthermore, Leveraging the digital revolution satta also came
              online. Khaiwals started their own websites, and apps to place
              bets. Social Media became a junction for bettors. Even the results
              started to come online. Although satta companies didn't have their
              own websites still, somehow khaiwals were able the manage it.
            </p>
            <p>
              There is a lot of website out on the internet that lets you play
              the satta online. Indeed, playing satta online gives bettors ease.
              Yet, they prefer the traditional method. In spite of all the boom,
              the traditional way of playing satta still remain alive. One such
              reason for that is its audience. Satta King's audience is the
              lower middle class and lower class, who are still learning about
              digitalization. People still go to khaiwal. Give them money, and
              they wage that money on their behalf and take some commission out
              of it.
            </p>
            <p>
              Furthermore, after giving the money to the khaiwal, bettors have
              to give them the predicted number. Single number if they are
              playing for Dhara and tens if playing for Jodi. Khaiwal will place
              their bet for them. After the results announce ( every company has
              fixed its time for the result), khaiwal will receive the money
              from the lottery company and delivers them to the winners. Khaiwal
              handles all the headache once the bet is placed.
            </p>

            <h2>How to play Satta King Online?</h2>
            <p>
              As said, there are various apps and websites that let you play
              satta online. It's totally up to the bettors on what platform they
              are comfortable with. Either they can choose a mobile app or
              website. Now, the real question arises, are they secure? Well,
              it's up to the user or bettor, from where they are playing the
              game. As it is known, lottery companies don't have any official
              sites. Every site that's out there is either managed by khaiwal or
              other mediators. They have taken the old traditional method
              online. Apart from that, Khaiwal's also run Whatsapp groups and
              telegram groups for the same. You've to message him with your
              number, send them the money digitally and he'll place the bet.
              After winning, he'll deliver the winning amount along with the
              satta king result.
            </p>
          </div>
          <div className="sattaking-info">
            <h2>How to get Satta King result?</h2>
            <p>
              Satta King is one of the most played satta games. Henceforth, its
              result is most awaited as well. Mostly, folks get results from
              their respective khaiwals. However, due to manual work, their
              results are mostly delayed. Apart from that, you can also get
              Satta King results online. You just have to search "satta king
              result" on google. There will be tons of results regarding the
              same. Satta King (http://gali-result.co) is also one of them.
              Gali-result provides the most accurate and timely results without
              a margin error. We rely on the best channels to deliver you the
              result.
            </p>

            <h2>Is Satta king legal?</h2>
            <p>
              Satta king comprises a lot of games, saying whether satta king is
              legal or not will be very vague. A few of the games are legal in a
              few parts of India, others are not. In Arunachal Pradesh, Assam,
              Goa, Kerela, Maharashtra, Meghalaya, Punjab, and Sikkim few
              lottery games have been legalized.
            </p>
            <p>
              However, gambling in India has been banned since the British
              government introduced the Public Gambling Act in 1867, stating
              betting is unfair. According to the act, the penalty for breaking
              this law was a fine of ₹200 or imprisonment of up to 3 months.
              Further, in 2005 the government updated the law.
            </p>
            <p>
              Therefore people are only allowed to bet on those games which have
              been legalized by the government and it is only limited to the
              people residing in that area. For others, it's still illegal. So,
              play at your own risk. Win Big, Play Safe!
            </p>

            <h2>Why Satta King is famous?</h2>
            <p>
              The popularity and fame of the satta king are related to the chaos
              of money among the lower middle class and middle class. They don't
              get many opportunities. Neither they are born rich, nor do they
              have the relevant resources. This economic and mental crisis
              attracts them to easy money. Satta King is the light they find.
              Their lust for money led them towards this. It makes Satta King
              famous among those classes. And as most of our population falls in
              that class, satta king has to be famous.
            </p>
            <h2>Is there any trick to win Satta King?</h2>
            <p>
              Well, there is not. Satta King is a completely number-based game
              based on the luck and precision of bettors. Tho, there are some
              guys from the lottery companies that leak numbers. Apart from that
              there isn't any trick. The only trick we give is only bet the
              money that you can happily lose. If you're blindly betting you'll
              end up losing all your money. Greed and Fear both should be
              balanced equally as equilibrium. Only then you can win or you'll
              have a chance to win.
            </p>

            <h2>What is Satta Record Chart?</h2>
            <p>
              Satta Record Chart is a collection of all famous satta games'
              results. The general audience of satta games is not so
              web-friendly. It becomes complicated for them to find live and
              updated results. For them, website owners make Satta Record Chart,
              where they can see live Satta king results as well as past
              results. This helps visitors of the website to see the lucky
              numbers without an issue. Here at gali-results.co you can find the
              Satta Record Chart of all the famous games. Our developers work
              round the clock to serve you the best. Apart from the live result,
              you can also check Satta Chart 2021 and Satta Chart 2020.
            </p>

            <h2>What is the biggest win of Satta King?</h2>
            <p>
              Biggest win! The volume of Satta king is so huge. Still, there is
              no valid record of that. How can anyone supposedly find the
              biggest win? Daily lakhs and cores of bets take place. Millions
              win, millions lost. And everything works so mysteriously that no
              one knows. Lottery Company keeps everything off the hook.
              Moreover, in all this, we know one thing for sure. Lottery
              Companies are making a fortune out of it. They are always winning.
              They make money in every game. So vaguely speaking, lottery
              companies are winning big. Every day, every time.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SattaInfo;
